<template>
  <section>
    <div class="content-header">
      <h2>Supporting belonging</h2>
    </div>
    <div class="content-wrapper">
      <p>The Greater Good Magazine article, <a href="https://greatergood.berkeley.edu/article/item/how_to_help_students_feel_a_sense_of_belonging_during_the_pandemic" target="_blank">How to Help Students Feel a Sense of Belonging During the Pandemic</a> suggests four research-based practices that you can use (and share) to cultivate a strong sense of belonging in your classroom.</p>
            <div class="carousel">
              <hooper
                ref="carousel_01"
                class="mb-4"
                :wheelControl="false"
              >
                <slide>
                  <div class="slide">
                    <p>1. Normalize challenges to belonging and provide strategies to overcome them</p>
                  </div>
                </slide>

                <slide>
                  <div class="slide">
                    <p>2. Make a plan to check in with students</p>
                  </div>
                </slide>

                <slide>
                  <div class="slide">
                    <p>3. Make a plan to give wise feedback when returning each assessment</p>
                  </div>
                </slide>

                <slide>
                  <div class="slide">
                    <p>4. Shout out your favorite mistake</p>
                  </div>
                </slide>

                <hooper-navigation slot="hooper-addons">
                  <img
                    slot="hooper-prev"
                    src="@/assets/img/_ui/svg/carousel-back.svg"
                    alt="previous"
                    srcset=""
                  >
                  <img
                    slot="hooper-next"
                    src="@/assets/img/_ui/svg/carousel-next.svg"
                    alt="next"
                    srcset=""
                  >
                </hooper-navigation>

                <hooper-pagination slot="hooper-addons"></hooper-pagination>
              </hooper>
            </div>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination
} from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
